(() => {
    function toggleLightnessMode() {
        document.documentElement.classList.toggle('dark');
        localStorage.getItem('color-theme') === 'dark' ? localStorage.setItem('color-theme', 'light') : localStorage.setItem('color-theme', 'dark');
    }

    const themeToggleBtns = document.querySelectorAll('.theme-toggle');
    themeToggleBtns.forEach((btn => btn.addEventListener('click', toggleLightnessMode)));
    themeToggleBtns.forEach((btn => btn.addEventListener('keyup',
        function (event) {
            if ([13, 32].includes(event.keyCode)) { toggleLightnessMode() }
        }
    )));
})();